import { Box, Icon, Link, List, Button } from '@mui/material';

import { LogoWithText } from '../components/LogoWithText';
import { WalletControls } from '../components/WalletControls';
import {
  FINANCE_PATH,
  HOME_PATH,
  POINTS_PATH,
  SIGNUP_AS_CREATOR_PATH,
  SIGNUP_PATH,
} from '../constants/routings';
import useCurrentUserData from '../hooks/auth/useCurrentUserData';

import MenuItem from './MenuItem';
import navigate from '../lib/navigate';
import { UserType } from '../types/UserType';

const LINKS = [
  {
    text: 'Trenches',
    href: HOME_PATH,
    alwaysVisible: true,
  },
  { text: 'Points', href: POINTS_PATH },
  { text: 'Finance', href: FINANCE_PATH, restricted: true },
];

export default function TopNavigationBar() {
  const { data: userData } = useCurrentUserData();

  const onboardingComplete = userData?.onboarding_complete || null;
  const isCreator = userData?.user_type === UserType.CREATOR;
  const isOnboardedCreator = isCreator && onboardingComplete;

  // Permissions to view tabs
  const canViewRestrictedTabs = isOnboardedCreator;
  const canViewTabs = !isCreator || isOnboardedCreator;

  const isLoggedIn = !!userData;

  return (
    <Box
      component="nav"
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        width: '100%',
        height: '60px',
        position: 'sticky',
        top: 0,
        zIndex: 1100,
        px: { xs: 5, md: 0 },
      }}
    >
      <Link href={HOME_PATH} sx={{ display: 'flex', alignItems: 'center' }}>
        <Icon
          sx={{
            width: { xs: 'auto', md: 180, xl: 'auto' },
            height: { xs: 'auto', md: 42, xl: 'auto' },
          }}
        >
          <LogoWithText />
        </Icon>
      </Link>
      {isLoggedIn && (
        <List
          sx={{
            display: 'flex',
            flexDirection: 'row',
            gap: 3,
            alignItems: 'center',
          }}
        >
          {LINKS.map(({ text, href, restricted, alwaysVisible }) => {
            if (restricted && !canViewRestrictedTabs) return null;
            if (!alwaysVisible && !restricted && !canViewTabs) return null;

            return <MenuItem key={text} href={href} text={text} />;
          })}
        </List>
      )}

      <Box sx={{ minWidth: '190px' }}>
        {isLoggedIn ? (
          <WalletControls display="flex" gap={2} />
        ) : (
          <Box sx={{ display: 'flex', gap: 3 }}>
            <Button
              variant="outlined"
              color="primary"
              onClick={() => navigate(SIGNUP_AS_CREATOR_PATH)}
            >
              Launch Your Trench
            </Button>
            <Button
              variant="text"
              color="primary"
              onClick={() => navigate(SIGNUP_PATH)}
            >
              Sign in
            </Button>
          </Box>
        )}
      </Box>
    </Box>
  );
}
