import React, { useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';

import { Box, Divider } from '@mui/material';

import { GlobalWarningContainer } from '../components/GlobalWarnings/GlobalWarningContainer';
import { Maintenance } from '../components/Maintenance';
import { IncorrectWalletWarningContent } from '../components/shared/Modals/IncorrectWalletWarningModal';
import { TelegramWebviewWarning } from '../components/TelegramWebviewWarning';
import {
  CREATORS_PATH,
  EDIT_PROFILE_PATH,
  PROFILE_PATH,
  SIGNUP_PATH,
} from '../constants/routings';
import {
  GlobalConnectErrors,
  useGlobalConnectError,
} from '../hooks/useWalletConnectError';
import { isTelegramWebview } from '../lib/isTelegramWebview';
import { usePageContext } from '../renderer/usePageContext';

import { OldPullPaymentApprovedWarningBlockingView } from './BlockingViews/OldPullPaymentApprovedWarningBlockingView';
import { WrongConnectorWarningBlockingView } from './BlockingViews/WrongConnectorWarningBlockingView';
import Content from './Content';
import TopNavigationBar from './TopNavigationBar';
import MobileHeader from './MobileHeader';
import MobileFooter from './MobileNavigation';
import useCurrentUserData from '../hooks/auth/useCurrentUserData';
import { SignupNotCompleted } from './BlockingViews/SignupNotCompleted';

export function AppLayout({ children }: React.PropsWithChildren) {
  const pageContext = usePageContext();
  const { urlParsed } = pageContext;
  const { errorType } = useGlobalConnectError();
  const { enqueueSnackbar } = useSnackbar();
  const { data: userData } = useCurrentUserData();

  const signUpNotCompleted =
    userData && (!userData.user_type || !userData.wallet_address);

  const [usingTelegramWebview, setUsingTelegramWebview] = useState(false);
  useEffect(() => setUsingTelegramWebview(isTelegramWebview()), []);

  useEffect(() => {
    if (urlParsed?.search?.discordLinked) {
      enqueueSnackbar(`Discord account added successfully`, {
        variant: 'success',
      });
    }
  }, []);

  const isCreatorPage = pageContext.urlPathname.startsWith(CREATORS_PATH);

  const showTopNavigationBar = !isCreatorPage;
  const isSignupPage = pageContext.urlPathname.startsWith(SIGNUP_PATH);

  if (import.meta.env.VITE_MAINTENANCE_ENABLED === 'true') {
    return <Maintenance />;
  }

  const hideMobileFooter =
    pageContext.urlPathname.startsWith(EDIT_PROFILE_PATH) ||
    (pageContext.urlPathname.startsWith(CREATORS_PATH) &&
      pageContext.urlPathname !== CREATORS_PATH);

  if (usingTelegramWebview) {
    return <TelegramWebviewWarning />;
  }

  if (signUpNotCompleted && !isSignupPage) {
    return <SignupNotCompleted />;
  }

  if (errorType === GlobalConnectErrors.WrongConnector) {
    return <WrongConnectorWarningBlockingView />;
  }

  if (errorType === GlobalConnectErrors.OldPullPaymentApproved) {
    return <OldPullPaymentApprovedWarningBlockingView />;
  }

  if (pageContext.urlPathname === SIGNUP_PATH) {
    return <>{children}</>;
  }

  if (errorType === GlobalConnectErrors.DifferFromLinkedWallet) {
    return (
      <>
        <GlobalWarningContainer />

        <Box
          sx={{
            width: '100vw',
            height: 'calc(100vh - 50px)',
            my: 'auto',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              p: 10,
              border: '1px solid #2D2F33',
              borderRadius: 3,
              maxWidth: '480px',
            }}
          >
            <IncorrectWalletWarningContent />
          </Box>
        </Box>
      </>
    );
  }

  if (
    pageContext.urlPathname.startsWith(CREATORS_PATH) &&
    pageContext.urlPathname.endsWith('/subscribe')
  ) {
    return (
      <>
        <GlobalWarningContainer />
        {children}
      </>
    );
  }

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100vh',
        overflow: 'hidden',
      }}
    >
      <GlobalWarningContainer />
      <Box
        display="flex"
        mx="auto"
        width={{ xs: '100vw', md: '1160px' }}
        sx={{ flexDirection: 'column' }}
      >
        {showTopNavigationBar && (
          <Box
            sx={{
              display: {
                xs: 'none',
                md: 'flex',
              },
            }}
          >
            <TopNavigationBar />
          </Box>
        )}
        <Box
          sx={{
            display: {
              xs: 'block',
              md: 'none',
            },
          }}
        >
          <MobileHeader />
          {!hideMobileFooter && <MobileFooter />}
        </Box>

        {!isCreatorPage && (
          <Divider
            orientation="vertical"
            sx={{
              height: 'auto',
              ml: 6,
              display: {
                xs: 'none',
                md: 'block',
              },
            }}
          />
        )}

        <Content>{children}</Content>
      </Box>
    </Box>
  );
}
