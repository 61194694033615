import { PropsWithChildren } from 'react';

import { Box } from '@mui/material';

import { PreviewBanner } from '../components/PreviewBanner';
import { CREATORS_PATH, PROFILE_PATH } from '../constants/routings';
import { useGetPreviewFromUrl } from '../hooks/useGetPreviewFromUrl';
import { usePageContext } from '../renderer/usePageContext';

export default function Content({ children }: PropsWithChildren) {
  const pageContext = usePageContext();
  const isProfilePage = pageContext.urlPathname === PROFILE_PATH;
  const isCreatorPage = pageContext.urlPathname.startsWith(CREATORS_PATH);
  const isPreview = useGetPreviewFromUrl();

  return (
    <Box>
      {isPreview && <PreviewBanner />}

      <Box
        component="main"
        display="flex"
        flexDirection="column"
        sx={{
          overflowY: isPreview || isCreatorPage ? 'none' : 'hidden',
          mt: { xs: 2, md: 0 },
          height: {
            xs: isProfilePage ? 'calc(100vh - 100px)' : '100vh',
            md: isCreatorPage ? 'auto' : 'calc(100vh - 32px)',
          },
          width: '100%',
        }}
      >
        {children}
      </Box>
    </Box>
  );
}
