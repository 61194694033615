import { Box, Link } from '@mui/material';

import { DISCORD_CONNECTION_GUIDE } from '../../../constants/externalLinks';
import { CREATORS_PATH } from '../../../constants/routings';
import { discordSelector } from '../../../constants/setupAccountSteps';
import CreatorSetupCard from '../Cards/CreatorSetupCard';
import { ModalContainer } from '../ModalContainer';

export interface ConnectDiscordModalProps {
  creatorName: string;
}

export function ConnectDiscordModal({ creatorName }: ConnectDiscordModalProps) {
  const redirectPath = `${CREATORS_PATH}/${creatorName}`;

  const handleCommunityPick = async () => {
    window.location.href = `/api/discord/link-account/${redirectPath}`;
  };

  return (
    <ModalContainer
      title="Join the Discord Community"
      contentProps={{
        sx: {
          width: 'min(calc(100% - 24px), 560px)',
          alignItems: 'flex-start',
        },
      }}
      description={
        <>
          {`Connect your Discord to join ${creatorName} exclusive community on Discord. `}
          <Link
            href={DISCORD_CONNECTION_GUIDE}
            color="primary"
            target="_blank"
            rel="noopener noreferrer"
            sx={{ cursor: 'pointer', textDecoration: 'underline' }}
          >
            Click here
          </Link>
          {' to learn how to how to connect discord.'}
        </>
      }
    >
      <CreatorSetupCard
        key={discordSelector.key}
        buttonText={discordSelector.buttonText}
        action={handleCommunityPick}
        title={discordSelector.title}
        description={'Connect your Discord account.'}
        icon={discordSelector.icon}
        isSmall
        communityCard
        sx={{
          pt: 6,
        }}
      />
    </ModalContainer>
  );
}
