import { Address, zeroAddress } from 'viem';
import { useSimulateContract } from 'wagmi';

import SubstakingVault from '../../../assets/abi/SubstakingVault';

export function getStakeAlttContract(
  vaultContractAddress: Address,
  receiver: Address,
  assets: bigint,
): {
  address: Address;
  abi: any;
  functionName: 'deposit';
  args: [bigint, Address];
} {
  return {
    address: vaultContractAddress,
    abi: SubstakingVault,
    functionName: 'deposit',
    args: [assets, receiver],
  };
}

export function useStakeAlttSimulateContract(
  vaultContractAddress: Address | undefined,
  myAddress: Address | undefined,
  amount: bigint | null,
) {
  return useSimulateContract({
    ...getStakeAlttContract(
      vaultContractAddress ?? zeroAddress,
      myAddress ?? zeroAddress,
      amount ?? 0n,
    ),
    query: {
      enabled: !!vaultContractAddress && !!myAddress && !!amount,
    },
  });
}
