import { Address } from 'viem';
import { useSimulateContract } from 'wagmi';

import SubstakingVault from '../../../assets/abi/SubstakingVault';

export function useWithdrawSimulateContract(
  vaultContractAddress: Address | undefined,
  amount: bigint | null,
  receiverAddress: Address | undefined,
  enabled: boolean = true,
) {
  return useSimulateContract({
    address: vaultContractAddress,
    abi: SubstakingVault,
    functionName: 'withdraw',
    args: [amount ?? 0n, receiverAddress, receiverAddress],
    query: {
      enabled: !!vaultContractAddress && !!amount && !!receiverAddress,
    },
  });
}
