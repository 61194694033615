import useGlobalModal from '../../../hooks/useGlobalModal';
import { ModalContainer } from '../ModalContainer';

import { TopUpWalletContent } from './Content/TopUpWalletContent';

export const DEFAULT_TOPUP_WALLET_TITLE = 'Top up your wallet';
export const DEFAULT_TOPUP_WALLET_DESCRIPTION = undefined;

export interface TopUpWalletModalProps {
  customTitle?: string;
  customDescription?: string;
}

export function TopUpWalletModal({
  customTitle,
  customDescription,
}: TopUpWalletModalProps) {
  const { hideModal } = useGlobalModal();

  const handleProceed = () => {
    hideModal();
  };

  return (
    <ModalContainer
      title={customTitle ?? DEFAULT_TOPUP_WALLET_TITLE}
      description={customDescription ?? DEFAULT_TOPUP_WALLET_DESCRIPTION}
      contentProps={{ sx: { width: 'min(calc(100% - 24px), 480px)' } }}
    >
      <TopUpWalletContent proceed={handleProceed} pt={10} />
    </ModalContainer>
  );
}
