import { PostgrestSingleResponse, SupabaseClient } from '@supabase/supabase-js';
import { QueryClient, useQuery } from '@tanstack/react-query';

import useSupabase from '../supabase';
import { IDiscordData } from '../../types/IDiscordData';

export function getDiscordDataQueryKey(userId: string) {
  return ['user', 'discord', userId];
}

export function invalidateDiscordData(
  queryClient: QueryClient,
  userId: string,
) {
  queryClient.invalidateQueries({
    queryKey: getDiscordDataQueryKey(userId),
  });
}

export function useUserDiscordDataByIdQuery(
  userId: string,
  skip: boolean = false,
) {
  const client = useSupabase();
  const queryKey = getDiscordDataQueryKey(userId);

  const queryFn = async () => {
    return getUserDiscordDataById(client, userId).then(
      (result: any) => result.data,
    );
  };

  return useQuery({ queryKey, queryFn, enabled: !skip });
}

export async function getUserDiscordDataById(
  client: SupabaseClient<any, 'public', any>,
  userId: string,
): Promise<PostgrestSingleResponse<IDiscordData | null>> {
  return client
    .from('discord_users')
    .select(
      'user_id, discord_user_id, discord_username, guild_id, guild_invite_link',
    )
    .eq('user_id', userId)
    .maybeSingle();
}
