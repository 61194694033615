import { Box, BoxProps, Button, ButtonProps, Typography } from '@mui/material';

import { SuccessSmallIcon, WarningIconSmall } from '../icons';
import { ChevronRight } from '../icons/chevronRight';
import { InfoIcon } from '../icons/InfoIcon';

export type FeedbackType = 'success' | 'error' | 'warning' | 'info';

export interface FeedbackInfo {
  type: FeedbackType;
  title: React.ReactNode;
  body?: React.ReactNode;
}

interface FeedbackBannerProps extends FeedbackInfo {
  buttonText?: string;
  onButtonClick?: () => void;
  icon?: React.ReactNode;
  buttonVariant?: ButtonProps['variant'];
}

export function FeedbackBanner({
  type,
  title,
  body,
  buttonText,
  onButtonClick,
  icon,
  buttonVariant = 'text',
  ...boxProps
}: FeedbackBannerProps & Omit<BoxProps, 'title'>) {
  const isOutlinedVariant = buttonVariant === 'outlined';
  return (
    <Box
      {...boxProps}
      sx={{
        padding: 3,
        display: 'flex',
        bgcolor: getBgColoroByType(type),
        flexDirection: { xs: isOutlinedVariant ? 'row' : 'column', md: 'row' },
        mt: { xs: 15, md: 6 },
        gap: 2,
        borderRadius: '10px',
        justifyContent: 'space-between',
        alignItems: 'flex-start',
        width: '100%',
        ...boxProps.sx,
      }}
    >
      <Box sx={{ display: 'flex', gap: 2 }}>
        <Box pt="2px">
          {icon ?? (
            <>
              {(type === 'error' || type === 'info') && (
                <InfoIcon color="white" />
              )}
              {type === 'warning' && <WarningIconSmall color="white" />}
              {type === 'success' && <SuccessSmallIcon />}
            </>
          )}
        </Box>

        <Box display="flex" flexDirection="column" gap={1}>
          <Typography variant="body2">{title}</Typography>

          {body && (
            <Typography fontSize="12px" color="#E5E5E5">
              {body}
            </Typography>
          )}
        </Box>
      </Box>

      {buttonText && (
        <Button
          variant={buttonVariant}
          sx={{
            color: 'white',
            whiteSpace: 'nowrap',
            fontWeight: '600',
            alignSelf: 'center',
          }}
          endIcon={!isOutlinedVariant && <ChevronRight size={16} />}
          onClick={onButtonClick}
          size="medium"
        >
          {buttonText}
        </Button>
      )}
    </Box>
  );

  function getBgColoroByType(type: FeedbackType) {
    return type === 'error'
      ? '#991B1B'
      : type === 'warning'
      ? '#7A1616'
      : type === 'success'
      ? '#182920'
      : type === 'info'
      ? '#262829'
      : 'black';
  }
}
