import { useSwitchChain } from 'wagmi';

import { Box, Button, Link, Typography } from '@mui/material';

import {
  COINBASE_SMART_WALLET_SWAP_LINK,
  WRAP_ETH_GUIDE_LINK,
} from '../../constants/externalLinks';
import useCurrentUserData from '../../hooks/auth/useCurrentUserData';
import { useIsAppReady } from '../../hooks/useIsAppReady';
import {
  GlobalConnectErrors,
  useGlobalConnectError,
} from '../../hooks/useWalletConnectError';
import { ChevronRight } from '../icons/chevronRight';

import { GlobalWarningWrapper } from './GlobalWarningWrapper';
import { typographyProps } from './typographyProps';

export function GlobalWarningContainer() {
  const { switchChain, chains } = useSwitchChain();
  const { errorType } = useGlobalConnectError();
  const { isReady } = useIsAppReady();
  const { data: userData } = useCurrentUserData();

  if (!isReady || !errorType) {
    return null;
  }

  if (errorType === GlobalConnectErrors.WrongConnector) {
    return (
      <GlobalWarningWrapper>
        <Typography {...typographyProps}>
          ⚠️ You have connected a wrong wallet type. Please connect a Coinbase
          Smart Wallet.
        </Typography>
      </GlobalWarningWrapper>
    );
  }

  if (errorType === GlobalConnectErrors.NoWalletConnected && userData) {
    return (
      <GlobalWarningWrapper>
        <Typography {...typographyProps}>
          ⚠️ You don`t have a wallet connected. Connect your wallet by using the
          “Connect wallet” button below
        </Typography>
      </GlobalWarningWrapper>
    );
  }

  if (errorType === GlobalConnectErrors.WrongNetwork) {
    return (
      <GlobalWarningWrapper>
        <Box display="flex" alignItems="center">
          <Typography {...typographyProps}>
            ⚠️ Wrong network selected for your wallet. Select “Base” as your
            wallet network
          </Typography>

          <Button
            variant="text"
            color="secondary"
            endIcon={<ChevronRight stroke="white" size={16} />}
            onClick={() => switchChain({ chainId: chains[0]?.id })}
            sx={{ py: 0, fontWeight: 600 }}
          >
            Switch network
          </Button>
        </Box>
      </GlobalWarningWrapper>
    );
  }

  if (errorType === GlobalConnectErrors.WalletNotLinked) {
    return (
      <GlobalWarningWrapper>
        <Typography {...typographyProps}>
          ⚠️ Your wallet not linked to this account
        </Typography>
      </GlobalWarningWrapper>
    );
  }

  if (errorType === GlobalConnectErrors.DifferFromLinkedWallet) {
    return (
      <GlobalWarningWrapper>
        <Typography {...typographyProps}>
          ⚠️ Your wallet doesn’t match the address on our database
        </Typography>
      </GlobalWarningWrapper>
    );
  }

  if (errorType === GlobalConnectErrors.SubscriptionsExpiredSoon) {
    return (
      <GlobalWarningWrapper>
        <Typography {...typographyProps}>
          ⚠️ One of your subscriptions is about to renew. Please, make sure you
          have enough WETH in your wallet. Need help? Check our{' '}
          <Link
            href={WRAP_ETH_GUIDE_LINK}
            target="_blank"
            rel="noopener noreferrer"
            color="text.primary"
            sx={{ cursor: 'pointer', textDecoration: 'underline' }}
          >
            guide
          </Link>
          {'. Tap '}
          <Link
            href={COINBASE_SMART_WALLET_SWAP_LINK}
            target="_blank"
            rel="noopener noreferrer"
            color="text.primary"
            sx={{ cursor: 'pointer', textDecoration: 'underline' }}
          >
            here
          </Link>
          {' to swap.'}
        </Typography>
      </GlobalWarningWrapper>
    );
  }

  //if (errorType === GlobalConnectErrors.EmailNotLinked) {
  //  return <EmailNotLinkedWarning />;
  //}
  return null;
}
