import { useRecoilState } from 'recoil';

import { modalState } from '../../../recoil/modal';

import { SwitchPlanModal } from './SwitchPlanModal/index';
import { AddEmailModal } from './AddEmailModal';
import { AffiliateLinkModal } from './AffiliateLinkModal';
import { BecomeCreatorModal } from './BecomeCreatorModal';
import { ConnectTelegramModal } from './ConnectTelegramModal';
import { DepositSuccessfulModal } from './DepositSuccessfulModal';
import { IncorrectWalletWarningModal } from './IncorrectWalletWarningModal';
import { PullPaymentApprovalModal } from './PullPaymentApprovalModal';
import { QuestModal } from './QuestModal';
import { ReferFriendsModal } from './ReferFriendsModal';
import { SetupPricingModal } from './SetupPricingModal';
import { ShareLinkModal } from './ShareLinkModal';
import { StakeModal } from './StakeModal';
import { StakeTermsModal } from './StakeTermsModal';
import { StakingLearnMoreModal } from './StakingLearnMoreModal';
import { TopUpWalletModal } from './TopUpWalletModal';
import { UnstakeModal } from './UnstakeModal';
import { UnstakeTermsModal } from './UnstakeTermsModal';
import { UnstakeWarningModal } from './UnstakeWarningModal';
import { UnsubscribeModal } from './UnsubscribeModal';
import { UsCitizenModal } from './UsCitizenModal';
import { CreatorOnboardingWelcomeModal } from './CreatorOnboardingWelcomeModal';
import { CommunityPickModal } from './CommunityPickModal';
import { ConnectDiscordModal } from './ConnectDiscordModal';

export const MODAL_TYPES = {
  UnsubscribeModal: 'UnsubscribeModal',
  DepositSuccessfulModal: 'DepositSuccessfulModal',
  ReferFriendsModal: 'ReferFriendsModal',
  AffiliateLinkModal: 'AffiliateLinkModal',
  ShareLinkModal: 'ShareLinkModal',
  ConnectTelegramModal: 'ConnectTelegramModal',
  SetupPricingModal: 'SetupPricingModal',
  TopUpWalletModal: 'TopUpWalletModal',
  StakeModal: 'StakeModal',
  QuestModal: 'QuestModal',
  SwitchPlanModal: 'SwitchPlanModal',
  StakingLearnMoreModal: 'StakingLearnMoreModal',
  UnstakeWarningModal: 'UnstakeWarningModal',
  UnstakeTermsModal: 'UnstakeTermsModal',
  UnstakeModal: 'UnstakeModal',
  StakeTermsModal: 'StakeTermsModal',
  IncorrectWalletWarningModal: 'IncorrectWalletWarningModal',
  BecomeCreatorModal: 'BecomeCreatorModal',
  UsCitizenModal: 'UsCitizenModal',
  PullPaymentApprovalModal: 'PullPaymentApprovalModal',
  AddEmailModal: 'AddEmailModal',
  CreatorOnboardingWelcomeModal: 'CreatorOnboardingWelcomeModal',
  CommunityPickModal: 'CommunityPickModal',
  ConnectDiscordModal: 'ConnectDiscordModal',
} as const;

const MODAL_COMPONENTS: any = {
  [MODAL_TYPES.UnsubscribeModal]: UnsubscribeModal,
  [MODAL_TYPES.DepositSuccessfulModal]: DepositSuccessfulModal,
  [MODAL_TYPES.ReferFriendsModal]: ReferFriendsModal,
  [MODAL_TYPES.AffiliateLinkModal]: AffiliateLinkModal,
  [MODAL_TYPES.ShareLinkModal]: ShareLinkModal,
  [MODAL_TYPES.ConnectTelegramModal]: ConnectTelegramModal,
  [MODAL_TYPES.SetupPricingModal]: SetupPricingModal,
  [MODAL_TYPES.TopUpWalletModal]: TopUpWalletModal,
  [MODAL_TYPES.StakeModal]: StakeModal,
  [MODAL_TYPES.QuestModal]: QuestModal,
  [MODAL_TYPES.SwitchPlanModal]: SwitchPlanModal,
  [MODAL_TYPES.StakingLearnMoreModal]: StakingLearnMoreModal,
  [MODAL_TYPES.UnstakeWarningModal]: UnstakeWarningModal,
  [MODAL_TYPES.UnstakeTermsModal]: UnstakeTermsModal,
  [MODAL_TYPES.UnstakeModal]: UnstakeModal,
  [MODAL_TYPES.StakeTermsModal]: StakeTermsModal,
  [MODAL_TYPES.IncorrectWalletWarningModal]: IncorrectWalletWarningModal,
  [MODAL_TYPES.BecomeCreatorModal]: BecomeCreatorModal,
  [MODAL_TYPES.UsCitizenModal]: UsCitizenModal,
  [MODAL_TYPES.PullPaymentApprovalModal]: PullPaymentApprovalModal,
  [MODAL_TYPES.AddEmailModal]: AddEmailModal,
  [MODAL_TYPES.CreatorOnboardingWelcomeModal]: CreatorOnboardingWelcomeModal,
  [MODAL_TYPES.CommunityPickModal]: CommunityPickModal,
  [MODAL_TYPES.ConnectDiscordModal]: ConnectDiscordModal,
};

const GlobalModal = () => {
  const modals = useRecoilState(modalState)[0] || [];

  if (!modals?.length) {
    return null;
  }

  return (
    <>
      {modals.map((modal) => {
        const modalProps = (modal as any).modalProps;

        const ModalComponent = MODAL_COMPONENTS[modal.modalType];

        return <ModalComponent key={modal.modalType} {...modalProps} />;
      })}
    </>
  );
};

export default GlobalModal;
