import { useState, useEffect } from 'react';
import { creatorConnectDiscordStep } from '../../../constants/setupAccountSteps';
import CreatorSetupCard from '../Cards/CreatorSetupCard';
import useIsDiscordConnected from '../../../hooks/auth/useIsDiscordConnected';

export function ConnectDiscordSetupCard() {
  const { guildConnected, isLoading: isDiscordLoading } =
    useIsDiscordConnected();
  const [isDiscordSetupDone, setIsDiscordSetupDone] = useState(false);

  useEffect(() => {
    if (guildConnected) {
      setIsDiscordSetupDone(true);
    }
  }, [guildConnected]);

  const action = () => {
    if (!isDiscordSetupDone) {
      window.open('/api/discord/link-server', '_blank');
    }
  };

  return (
    <CreatorSetupCard
      key={creatorConnectDiscordStep.key}
      buttonText={creatorConnectDiscordStep.buttonText}
      action={action}
      isDone={isDiscordSetupDone}
      disabled={isDiscordSetupDone || isDiscordLoading}
      title={creatorConnectDiscordStep.title}
      description={creatorConnectDiscordStep.description}
      icon={creatorConnectDiscordStep.icon}
    />
  );
}
