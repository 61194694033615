import { Box } from '@mui/material';
import { LoginButton } from '@telegram-auth/react';

export function TelegramWidget() {
  return (
    <Box sx={{ opacity: 0, width: 0, height: 0, overflow: 'hidden' }}>
      <LoginButton
        botUsername={import.meta.env.VITE_BOT_USERNAME!}
        authCallbackUrl="/api/tg"
        buttonSize="large"
        cornerRadius={100}
        showAvatar={false}
        lang="en"
      />
    </Box>
  );
}
