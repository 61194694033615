import { Box, Button, Grid, GridProps, Typography } from '@mui/material';

import { CheckRounded } from '../../icons';
import { ChevronRight } from '../../icons/chevronRight';

export default function CreatorSetupCard({
  icon,
  title,
  description,
  isDone,
  buttonText,
  action,
  disabled,
  isSmall,
  communityCard,
  ...gridProps
}: {
  title: string | JSX.Element;
  icon?: JSX.Element;
  description: string;
  isDone?: boolean;
  buttonText?: string;
  action?: () => void;
  disabled?: boolean;
  isSmall?: boolean;
  communityCard?: boolean;
} & GridProps) {
  const defaultBackground =
    'linear-gradient(147.18deg, rgba(56, 255, 147, 0.04) 7.31%, rgba(34, 153, 88, 0) 127.25%)';
  const hoverBackground =
    'linear-gradient(154.38deg, rgba(56, 255, 147, 0.08) 3.43%, rgba(34, 153, 88, 0) 218.2%)';
  const doneOrDisabledBackground = '#111314';

  return (
    <Grid item xs={1} md={1} {...gridProps}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          py: 6,
          px: 6,
          width: { xs: '100%', md: isSmall ? '260px' : '100%' },
          height: '225px',
          justifyContent: 'space-between',
          borderRadius: 4,
          border: '1px solid #262829',
          background:
            isDone || disabled || communityCard
              ? doneOrDisabledBackground
              : defaultBackground,
          transition: 'background 0.3s ease-in-out',
          '&:hover': {
            background:
              !isDone && !disabled ? hoverBackground : doneOrDisabledBackground,
          },
          cursor: disabled || isDone ? 'not-allowed' : 'pointer',
        }}
        onClick={!disabled ? action : undefined}
      >
        <Box>
          {!isDone ? icon : <CheckRounded />}

          <Typography
            sx={{
              fontSize: '20px',
              lineHeight: '25px',
              color: '#F4F4F5',
              fontWeight: 500,
              pt: 4,
            }}
          >
            {title}
          </Typography>

          <Typography
            sx={{
              fontSize: '14px',
              lineHeight: '17px',
              color: '#9B9FA3',
              fontWeight: 300,
              pt: 2,
            }}
          >
            {description}
          </Typography>
        </Box>
        {isDone ? (
          <Box>
            <Typography
              sx={{
                fontSize: '16px',
                lineHeight: '16px',
                color: '#9B9FA3',
                fontWeight: 600,
              }}
            >
              Done
            </Typography>
          </Box>
        ) : (
          <Button
            variant="text"
            sx={{
              color: isDone ? '#9B9FA3' : '#38FF93',
              width: 'fit-content',
              mb: -3,
            }}
            disabled={disabled}
            endIcon={
              !isDone &&
              !disabled && <ChevronRight size={16} stroke="#38FF93" />
            }
          >
            {buttonText}
          </Button>
        )}
      </Box>
    </Grid>
  );
}
