import { Box, Button, Link, Typography } from '@mui/material';

import {
  LEARN_MORE_ABOUT_STAKING_IN_COMMUNITY_POOLS_LINK,
  LEARN_MORE_CREATOR_GAMES,
  LEARN_MORE_YIELD_BOOST_MECHANISM,
} from '../../../constants/externalLinks';
import useGlobalModal from '../../../hooks/useGlobalModal';
import { ModalContainer } from '../ModalContainer';
import { Text } from '../Text';

export function UnstakeTermsModal() {
  const { hideModal } = useGlobalModal();

  const handleClick = () => {
    hideModal('UnstakeTermsModal');
  };

  return (
    <ModalContainer
      title="Warning: Unstaking now might result in losing two benefits: your ALTT yield boost and sXP rewards."
      contentProps={{ sx: { width: 'min(calc(100% - 24px), 960px)' } }}
    >
      <>
        <Box
          display="flex"
          flexDirection="column"
          maxHeight="350px"
          mt={5}
          mb={10}
          gap={5}
          sx={{ overflowY: 'auto' }}
        >
          <Box>
            <Text>Losing Your Yield Boost:</Text>
            <Text component="li" color="text.primary">
              If you unstake more than your initial staking amount, you'll lose
              all your accumulated yield boost. Click{' '}
              <Link
                target={'_blank'}
                rel="noopener noreferrer"
                href={LEARN_MORE_YIELD_BOOST_MECHANISM}
                sx={{ textDecoration: 'underline' }}
              >
                here
              </Link>{' '}
              to learn more about how the Yield Boost Mechanism works.
            </Text>
          </Box>

          <Box>
            <Text>Missed sXP Rewards:</Text>
            <Text component="li" color="text.primary">
              Unstake now and you'll stop earning daily sXP.
            </Text>
          </Box>

          <Box>
            <Text>Summary:</Text>
            <Text component="li" color="text.primary">
              While you have the flexibility to unstake at any time, remember
              that your sXP is actively growing and supporting your Trench.
            </Text>
          </Box>

          <Typography color="text.primary" fontSize="14px" fontWeight={300}>
            To learn more about how Trench Staking Pools work, click{' '}
            <Link
              target={'_blank'}
              rel="noopener noreferrer"
              href={LEARN_MORE_ABOUT_STAKING_IN_COMMUNITY_POOLS_LINK}
              sx={{ textDecoration: 'underline' }}
            >
              here
            </Link>
          </Typography>
        </Box>

        <Button onClick={handleClick}>Got it</Button>
      </>
    </ModalContainer>
  );
}
