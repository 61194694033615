import { setupPricingDefaultStep } from '../../../constants/setupAccountSteps';
import useGlobalModal from '../../../hooks/useGlobalModal';
import { useProfileSetupProgress } from '../../../providers/ProfileSetupProgressProvider';
import { DangerOrange } from '../../icons';
import CreatorSetupCard from '../Cards/CreatorSetupCard';

export function SetupPricingCard({ isUsaUser }: { isUsaUser: boolean }) {
  const { showModal } = useGlobalModal();

  const { isSubPricesSetupDone, isTopUpSetupDone } = useProfileSetupProgress();

  const topUpFirstWarning = !isSubPricesSetupDone && !isTopUpSetupDone;

  let description, title, buttonText, icon;

  switch (true) {
    case isUsaUser:
      description = 'Users from USA can’t sign up as Creator.';
      title = 'You can’t setup pricing';
      buttonText = 'Learn more';
      icon = <DangerOrange />;
      break;
    case topUpFirstWarning:
      description = 'You need to top up your wallet first.';
      title = setupPricingDefaultStep.title;
      buttonText = setupPricingDefaultStep.buttonText;
      icon = <DangerOrange />;
      break;
    default:
      description = setupPricingDefaultStep.description;
      title = setupPricingDefaultStep.title;
      buttonText = setupPricingDefaultStep.buttonText;
      icon = setupPricingDefaultStep.icon;
      break;
  }

  const onItemClick = () => {
    if (isUsaUser) {
      showModal({ modalType: 'UsCitizenModal' });
    } else if (!topUpFirstWarning) {
      showModal({ modalType: 'SetupPricingModal' });
    }
  };

  return (
    <CreatorSetupCard
      key={setupPricingDefaultStep.key}
      action={onItemClick}
      isDone={isSubPricesSetupDone}
      title={title}
      description={description}
      disabled={topUpFirstWarning || isSubPricesSetupDone}
      icon={icon}
      buttonText={buttonText}
    />
  );
}
