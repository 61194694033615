import {
  Link,
  ListItem,
  ListItemButton,
  ListItemText,
  useTheme,
} from '@mui/material';

import { HOME_PATH } from '../constants/routings';
import { usePageContext } from '../renderer/usePageContext';

export default function MenuItem({
  href,
  text,
  onClick,
}: {
  href: string;
  text: string;
  onClick?: any;
}) {
  const pageContext = usePageContext();
  const isActive =
    pageContext.urlPathname === href ||
    pageContext.urlPathname.startsWith(href + HOME_PATH);

  const theme = useTheme();

  const textColor = !isActive
    ? theme.palette.text.primary
    : theme.palette.text.active;

  const background = !isActive
    ? 'transparent'
    : theme.palette.background.active;

  return (
    <ListItem key={href} disablePadding>
      <ListItemButton
        component={Link}
        href={href}
        key={text}
        sx={{
          color: textColor,
          background,
          borderRadius: '100px',
          py: '9px',
          px: 4,
        }}
        onClick={onClick}
      >
        <ListItemText primary={text} />
      </ListItemButton>
    </ListItem>
  );
}
