import { PostgrestSingleResponse, SupabaseClient } from '@supabase/supabase-js';
import { QueryClient, useQuery } from '@tanstack/react-query';

import { ITelegramData } from '../../types/ITelegramData';
import useSupabase from '../supabase';

export function getTelegramDataQueryKey(userId: string) {
  return ['user', 'tg', userId];
}

export function invalidateTelegramData(
  queryClient: QueryClient,
  userId: string,
) {
  queryClient.invalidateQueries({
    queryKey: getTelegramDataQueryKey(userId),
  });
}

export function useUserTgDataByIdQuery(userId: string, skip: boolean = false) {
  const client = useSupabase();
  const queryKey = getTelegramDataQueryKey(userId);

  const queryFn = async () => {
    return getUserTgDataById(client, userId).then((result: any) => result.data);
  };

  return useQuery({ queryKey, queryFn, enabled: !skip });
}

export async function getUserTgDataById(
  client: SupabaseClient<any, 'public', any>,
  userId: string,
): Promise<PostgrestSingleResponse<ITelegramData | null>> {
  return client
    .from('telegram_users')
    .select('user_id, tg_user_id, tg_chat_id, tg_chat_invite_link')
    .eq('user_id', userId)
    .maybeSingle();
}
