import { SupabaseClient } from '@supabase/supabase-js';
import { useMutation } from '@tanstack/react-query';
import useSupabase from '../../supabase';

export function useSetOnboardingCompleteMutation() {
  const client = useSupabase();

  const mutationFn = async (userId: string) => {
    return setOnboardingComplete(client, userId).then(
      (result: any) => result.data,
    );
  };

  return useMutation({ mutationFn });
}

export async function setOnboardingComplete(
  client: SupabaseClient<any, 'public', any>,
  userId: string,
) {
  return client
    .from('users')
    .update({ onboarding_complete: true })
    .eq('id', userId)
    .throwOnError()
    .single();
}
