import { useState } from 'react';
import { useSnackbar } from 'notistack';
import { Address } from 'viem';
import { useWriteContract } from 'wagmi';

import { Box, Button, Divider, Link, Tooltip, Typography } from '@mui/material';

import { LEARN_MORE_UNSTAKING } from '../../../constants/externalLinks';
import { ZERO_ADDRESS } from '../../../constants/web3';
import { useAlttWethConverter } from '../../../hooks/useAlttWethConverter';
import useGlobalModal from '../../../hooks/useGlobalModal';
import { useMainTokenInfo } from '../../../hooks/useMainTokenInfo';
import { usePriceInUsd } from '../../../hooks/usePriceInUsd';
import { useUnstakeTokenInfo } from '../../../hooks/useUnstakeTokenInfo';
import { useWalletData } from '../../../hooks/useWalletData';
import { formatBigint } from '../../../lib/formatBigInt';
import { ICreator } from '../../../types/ICreator';
import { StakeTokenType } from '../../../types/StakeToken';
import {
  FeedbackDetails,
  getErrorDetails,
} from '../../../web3/getErrorDetails';
import { useGetCreatorVaultByAddress } from '../../../web3/hooks/SubstakingFactory/useGetVaultByAddress';
import { useGetMaxWithdrawReadContract } from '../../../web3/hooks/SubstakingVault/read/useGetMaxWithdrawReadContract';
import { useGetWethDepositReadContract } from '../../../web3/hooks/SubstakingVault/read/useGetWethDepositReadContract';
import { useWithdrawSimulateContract } from '../../../web3/hooks/SubstakingVault/useWithdrawSimulateContract';
import { useWithdrawWethSimulateContract } from '../../../web3/hooks/SubstakingVault/useWithdrawWethSimulateContract';
import { RotatedArrowsUpDownIcon } from '../../icons/RotatedArrowsUpDownIcon';
import { FeedbackBanner } from '../FeedbackBanner';
import { TokenAmountInput } from '../Inputs/TokenAmountInput';
import { ModalContainer } from '../ModalContainer';

import { ErrorWithTooltip } from './ErrorWithTooltip';

export type CreatorToUnstake = Pick<ICreator, 'wallet_address'>;

export interface UnstakeModalProps {
  creator: CreatorToUnstake;
  unstakeEth?: boolean;
  unstakeAltt?: boolean;
}

export function UnstakeModal({
  creator,
  unstakeEth = true, // TODO: send a parameter
  unstakeAltt = true,
}: UnstakeModalProps) {
  const { hideModal } = useGlobalModal();

  const [selectedToken, setSelectedToken] = useState(
    unstakeAltt ? StakeTokenType.ALTT : StakeTokenType.ETH,
  );

  const { symbol: alttSymbol, decimals: alttDecimals } = useUnstakeTokenInfo();
  const { address } = useWalletData();
  const { symbol, decimals: decimals } = useMainTokenInfo();

  const selectedTokenSymbol =
    selectedToken === StakeTokenType.ALTT ? alttSymbol : symbol;

  const selectedTokenDecimals =
    selectedToken === StakeTokenType.ALTT ? alttDecimals : decimals;

  const nextTokenToSelect =
    selectedToken === StakeTokenType.ALTT ? symbol : alttSymbol;

  const [enteredAmountInTokenWei, setEnteredAmountInTokenWei] = useState<
    bigint | null
  >(0n);

  const enteredAmountInTokenFormatted = formatBigint(
    enteredAmountInTokenWei,
    selectedTokenDecimals,
  );

  const { data: creatorVaultAddress } = useGetCreatorVaultByAddress(
    creator?.wallet_address,
  );

  const { data: maxWethWithdraw } = useGetWethDepositReadContract(
    creatorVaultAddress as Address,
    address,
    selectedToken === StakeTokenType.ETH,
  );

  const { data: maxAlttWithdraw } = useGetMaxWithdrawReadContract(
    creatorVaultAddress as Address,
    address,
    selectedToken === StakeTokenType.ALTT,
  );

  const availableToWithdrawInTokenAtom =
    selectedToken === StakeTokenType.ALTT
      ? (maxAlttWithdraw as bigint)
      : (maxWethWithdraw as bigint);

  const availableToWithdrawInTokenFormatted = formatBigint(
    availableToWithdrawInTokenAtom,
    selectedTokenDecimals,
  );

  const { convertToUSD } = usePriceInUsd('');
  const { convertAlttToEthWei } = useAlttWethConverter();

  const unstakedAmountInEthWei =
    selectedToken === StakeTokenType.ALTT
      ? convertAlttToEthWei(enteredAmountInTokenWei)
      : enteredAmountInTokenWei;

  const unstakedAmountInEthFormatted = formatBigint(
    unstakedAmountInEthWei,
    selectedTokenDecimals,
  );

  const unstakedAmountInUsdStr = unstakedAmountInEthFormatted
    ? convertToUSD(+unstakedAmountInEthFormatted)
    : 'N/A';

  const simulateWithdrawWethResult = useWithdrawWethSimulateContract(
    creatorVaultAddress as Address,
    enteredAmountInTokenWei,
    selectedToken === StakeTokenType.ETH,
  );

  const simulateWithdrawAlttResult = useWithdrawSimulateContract(
    creatorVaultAddress as Address,
    enteredAmountInTokenWei,
    address,
    selectedToken === StakeTokenType.ALTT,
  );

  const { enqueueSnackbar } = useSnackbar();

  const onUnstakeSuccess = (hash: string) => {
    enqueueSnackbar(`Unstaked successfully! TX hash: ${hash}`, {
      variant: 'success',
    });

    window.location.reload();
  };

  const { writeContract: writeStake } = useWriteContract({
    mutation: {
      onSuccess: onUnstakeSuccess,
    },
  });

  const simulatedResult =
    selectedToken === StakeTokenType.ALTT
      ? simulateWithdrawAlttResult
      : simulateWithdrawWethResult;
  const simulatedRequest = simulatedResult.data?.request;

  const handleUnstake = async () => {
    if (simulatedRequest) {
      writeStake(simulatedRequest);
    }
  };

  const onCancelClick = () => {
    hideModal();
  };

  const setMaxAvailableValue = () => {
    setEnteredAmountInTokenWei(
      (availableToWithdrawInTokenAtom as bigint) ?? null,
    );
  };

  const hasCorrectVaultAddress =
    creatorVaultAddress && creatorVaultAddress !== ZERO_ADDRESS;

  const hasEnoughBalanceToUnstake =
    enteredAmountInTokenWei === 0n ||
    (availableToWithdrawInTokenAtom &&
      enteredAmountInTokenWei &&
      availableToWithdrawInTokenAtom >= enteredAmountInTokenWei);

  const unstakeDisabled =
    !hasCorrectVaultAddress || !hasEnoughBalanceToUnstake || !simulatedRequest;

  const showErrorAfterEnteredBalance = !hasEnoughBalanceToUnstake;

  const stakeErrorDetails = (() => {
    switch (true) {
      case !hasCorrectVaultAddress:
        return UNSTAKE_ERRORS_MAP.INCORRECT_VAULT_ADDRESS;
      case !hasEnoughBalanceToUnstake:
        return UNSTAKE_ERRORS_MAP.IB;
    }
    return getErrorDetails(simulatedResult.failureReason, UNSTAKE_ERRORS_MAP);
  })();

  const changeSelectedToken = () => {
    setSelectedToken((value) =>
      value === StakeTokenType.ALTT ? StakeTokenType.ETH : StakeTokenType.ALTT,
    );
  };

  return (
    <ModalContainer
      title="Unstake"
      contentProps={{ sx: { width: 'min(calc(100% - 24px), 480px)' } }}
    >
      <Box mt={{ xs: 6, md: 10 }}>
        <TokenAmountInput
          title="Enter amount"
          decimals={selectedTokenDecimals}
          value={enteredAmountInTokenWei}
          onValueChanged={setEnteredAmountInTokenWei}
          inputVariant="text"
          inputProps={{
            disabled: false,
          }}
        />

        <Box display="flex" justifyContent="space-between" mt={2}>
          <Typography
            color="text.secondary"
            fontSize={{ xs: 14, md: 16 }}
            fontWeight={300}
          >
            {selectedTokenSymbol}
          </Typography>

          {unstakeEth && unstakeAltt && (
            <Button
              startIcon={
                <RotatedArrowsUpDownIcon
                  rotate={+(selectedToken === StakeTokenType.ALTT)}
                  disabled={false}
                />
              }
              variant="text"
              disabled={false}
              onClick={changeSelectedToken}
            >
              Enter {nextTokenToSelect}
            </Button>
          )}
        </Box>

        <Box display="inline-block">
          <Typography
            component="span"
            sx={{
              fontSize: '14px',
              fontWeight: 300,
            }}
          >
            ≈ {unstakedAmountInUsdStr}
          </Typography>

          <Typography
            component="span"
            sx={{
              fontSize: '14px',
              fontWeight: 300,
            }}
            color="text.secondary"
          >
            {' USD'}
          </Typography>
        </Box>

        {!!stakeErrorDetails && showErrorAfterEnteredBalance && (
          <ErrorWithTooltip errorDetails={stakeErrorDetails} sx={{ mt: 4 }} />
        )}

        <Divider sx={{ my: { xs: 5, md: 6 } }} />

        <Box display="flex" alignItems="center">
          <Box display="flex" flexDirection="column" gap={1}>
            <Typography
              color="text.secondary"
              fontSize={{ xs: 14, md: 16 }}
              fontWeight={300}
            >
              Available amount
            </Typography>

            <Typography
              color="text.primary"
              fontSize={{ xs: 14, md: 16 }}
              fontWeight={300}
            >
              {availableToWithdrawInTokenFormatted} {selectedTokenSymbol}
            </Typography>
          </Box>

          <Button
            variant="outlined"
            color="primary"
            sx={{
              ml: 'auto',
            }}
            onClick={setMaxAvailableValue}
          >
            Max
          </Button>
        </Box>

        <Divider sx={{ my: { xs: 5, md: 6 } }} />

        <Box display="flex" gap={2} justifyContent="space-between" my={2}>
          <Typography
            color="text.primary"
            fontSize={{ xs: 14, md: 16 }}
            fontWeight={300}
          >
            You`re receiving
          </Typography>

          <Typography
            color="text.primary"
            fontSize={{ xs: 14, md: 16 }}
            fontWeight={500}
          >
            {enteredAmountInTokenFormatted} {selectedToken}
          </Typography>
        </Box>

        <Box display="flex" flexDirection="column" gap={4} sx={{ mt: 4 }}>
          <ErrorWithTooltip
            errorDetails={{
              type: 'warning',
              title: (
                <>
                  Warning: Unstaking now might result in losing two benefits:
                  your ALTT yield boost and sXP rewards. Click{' '}
                  <Link
                    href={LEARN_MORE_UNSTAKING}
                    color="inherit"
                    target="_blank"
                    rel="noopener noreferrer"
                    sx={{ cursor: 'pointer', textDecoration: 'underline' }}
                  >
                    here
                  </Link>{' '}
                  to learn more.
                </>
              ),
            }}
          />

          {!!stakeErrorDetails && !showErrorAfterEnteredBalance && (
            <ErrorWithTooltip errorDetails={stakeErrorDetails} />
          )}
        </Box>

        <Button
          disabled={unstakeDisabled}
          onClick={handleUnstake}
          sx={{ width: { xs: '100%', md: '100%' }, mt: 6 }}
        >
          Unstake
        </Button>
      </Box>
    </ModalContainer>
  );
}

export const UNSTAKE_ERRORS_MAP: Record<string, FeedbackDetails> = {
  IB: {
    title:
      'Unstaking amount should not be more than maximum available amount to unstake.',
    body: 'Enter smaller amount to ustake.',
    type: 'warning',
  },
  INCORRECT_VAULT_ADDRESS: {
    title: "This creator doesn't have correct vault address",
    body: 'Creator should create vault by set up subscriptions prices',
    type: 'warning',
  },
};
