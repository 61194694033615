import { Box } from '@mui/material';

import { OldPullPaymentApprovedWarningContent } from './OldPullPaymentApprovedWarningContent';

export function OldPullPaymentApprovedWarningBlockingView() {
  return (
    <Box
      sx={{
        width: '100vw',
        height: '100vh',
        my: 'auto',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          p: 10,
          border: '1px solid #2D2F33',
          borderRadius: 3,
          maxWidth: '520px',
        }}
      >
        <OldPullPaymentApprovedWarningContent />
      </Box>
    </Box>
  );
}
