import { Address, erc20Abi } from 'viem';
import { useSimulateContract } from 'wagmi';

export function getApproveContract(
  contractAddress: Address,
  amount: bigint,
  erc20Address: Address = import.meta.env
    .VITE_TOKEN_CONTRACT_ADDRESS as Address,
): {
  address: Address;
  abi: typeof erc20Abi;
  functionName: 'approve';
  args: [Address, bigint];
} {
  return {
    address: erc20Address,
    abi: erc20Abi,
    functionName: 'approve',
    args: [contractAddress, amount],
  };
}

export function useApproveSimulateContract(
  contractSpenderAddress: Address,
  selectedPriceInWei: bigint | undefined,
  erc20Address: Address = import.meta.env
    .VITE_TOKEN_CONTRACT_ADDRESS as Address,
) {
  return useSimulateContract({
    ...getApproveContract(
      contractSpenderAddress,
      selectedPriceInWei ?? 0n,
      erc20Address,
    ),
    query: {
      enabled: !!contractSpenderAddress && !!selectedPriceInWei,
    },
  });
}
