import { atom } from 'recoil';

import { AffiliateLinkModalProps } from '../components/shared/Modals/AffiliateLinkModal';
import { CommunityPickModalProps } from '../components/shared/Modals/CommunityPickModal';
import { ConnectDiscordModalProps } from '../components/shared/Modals/ConnectDiscordModal';
import { MODAL_TYPES } from '../components/shared/Modals/GlobalModal';
import { PullPaymentApprovalModalProps } from '../components/shared/Modals/PullPaymentApprovalModal';
import { QuestModalProps } from '../components/shared/Modals/QuestModal';
import { SetupPricingModalProps } from '../components/shared/Modals/SetupPricingModal';
import { ShareLinkModalProps } from '../components/shared/Modals/ShareLinkModal';
import { StakeModalProps } from '../components/shared/Modals/StakeModal';
import { StakeTermsModalProps } from '../components/shared/Modals/StakeTermsModal';
import { StakingLearnMoreModalProps } from '../components/shared/Modals/StakingLearnMoreModal';
import { SwitchPlanModalProps } from '../components/shared/Modals/SwitchPlanModal';
import { TopUpWalletModalProps } from '../components/shared/Modals/TopUpWalletModal';
import { UnstakeModalProps } from '../components/shared/Modals/UnstakeModal';
import { UnstakeWarningModalProps } from '../components/shared/Modals/UnstakeWarningModal';
import { UnsubscribeModalProps } from '../components/shared/Modals/UnsubscribeModal';

export interface UnsubscribeModalType {
  modalType: typeof MODAL_TYPES.UnsubscribeModal;
  modalProps: UnsubscribeModalProps;
}

export interface DepositSuccessfulModalType {
  modalType: typeof MODAL_TYPES.DepositSuccessfulModal;
  modalProps: undefined;
}
export interface ReferFriendsModalType {
  modalType: typeof MODAL_TYPES.ReferFriendsModal;
}

export interface AffiliateLinkModalType {
  modalType: typeof MODAL_TYPES.AffiliateLinkModal;
  modalProps: AffiliateLinkModalProps;
}

export interface ShareLinkModalType {
  modalType: typeof MODAL_TYPES.ShareLinkModal;
  modalProps: ShareLinkModalProps;
}

export interface ConnectTelegramModalType {
  modalType: typeof MODAL_TYPES.ConnectTelegramModal;
  modalProps: undefined;
}

export interface SetupPricingModalType {
  modalType: typeof MODAL_TYPES.SetupPricingModal;
  modalProps: SetupPricingModalProps;
}

export interface TopUpWalletModalType {
  modalType: typeof MODAL_TYPES.TopUpWalletModal;
  modalProps?: TopUpWalletModalProps;
}

export interface StakeModalType {
  modalType: typeof MODAL_TYPES.StakeModal;
  modalProps: StakeModalProps;
}

export interface QuestModalType {
  modalType: typeof MODAL_TYPES.QuestModal;
  modalProps: QuestModalProps;
}

export interface SwitchPlanModalType {
  modalType: typeof MODAL_TYPES.SwitchPlanModal;
  modalProps: SwitchPlanModalProps;
}

export interface StakingLearnMoreModalType {
  modalType: typeof MODAL_TYPES.StakingLearnMoreModal;
  modalProps: StakingLearnMoreModalProps;
}

export interface UnstakeWarningModalType {
  modalType: typeof MODAL_TYPES.UnstakeWarningModal;
  modalProps: UnstakeWarningModalProps;
}

export interface UnstakeTermsModalType {
  modalType: typeof MODAL_TYPES.UnstakeTermsModal;
}

export interface UnstakeModalType {
  modalType: typeof MODAL_TYPES.UnstakeModal;
  modalProps: UnstakeModalProps;
}

export interface StakeTermsModalType {
  modalType: typeof MODAL_TYPES.StakeTermsModal;
  modalProps: StakeTermsModalProps;
}

export interface IncorrectWalletWarningModalType {
  modalType: typeof MODAL_TYPES.IncorrectWalletWarningModal;
}

export interface BecomeCreatorModalType {
  modalType: typeof MODAL_TYPES.BecomeCreatorModal;
}

export interface UsCitizenModalType {
  modalType: typeof MODAL_TYPES.UsCitizenModal;
}

export interface PullPaymentApprovalModalType {
  modalType: typeof MODAL_TYPES.PullPaymentApprovalModal;
  modalProps: PullPaymentApprovalModalProps;
}

export interface AddEmailModalType {
  modalType: typeof MODAL_TYPES.AddEmailModal;
}

export interface CreatorOnboardingWelcomeModalType {
  modalType: typeof MODAL_TYPES.CreatorOnboardingWelcomeModal;
}

export interface CommunityPickModalType {
  modalType: typeof MODAL_TYPES.CommunityPickModal;
  modalProps: CommunityPickModalProps;
}

export interface ConnectDiscordModalType {
  modalType: typeof MODAL_TYPES.ConnectDiscordModal;
  modalProps: ConnectDiscordModalProps;
}

export type ModalType =
  | UnsubscribeModalType
  | DepositSuccessfulModalType
  | ReferFriendsModalType
  | AffiliateLinkModalType
  | ShareLinkModalType
  | ConnectTelegramModalType
  | SetupPricingModalType
  | TopUpWalletModalType
  | StakeModalType
  | QuestModalType
  | SwitchPlanModalType
  | StakingLearnMoreModalType
  | UnstakeWarningModalType
  | UnstakeTermsModalType
  | UnstakeModalType
  | StakeTermsModalType
  | IncorrectWalletWarningModalType
  | BecomeCreatorModalType
  | UsCitizenModalType
  | PullPaymentApprovalModalType
  | AddEmailModalType
  | CreatorOnboardingWelcomeModalType
  | CommunityPickModalType
  | ConnectDiscordModalType;

export const modalState = atom<ModalType[] | null>({
  key: 'modalState',
  default: null,
});
