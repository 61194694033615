import { Box, Divider, Skeleton, Typography } from '@mui/material';
import { isAddress } from 'ethers';
import { useUserByWalletAddressQuery } from '../../api/users/useUserByWalletAddressQuery';
import { truncAddress } from '../../lib/truncHash';
import UserAvatar from './UserAvatar';
import { UseQueryResult } from '@tanstack/react-query';
import { UserProfile } from '../../api/types/getUserProfile';

export function ReferredBySection({
  affiliateWallet,
  hasDivider,
  userInfo,
}: {
  affiliateWallet: string | null;
  hasDivider?: boolean;
  userInfo?: '' | UseQueryResult<UserProfile, Error> | null;
}) {
  if (!affiliateWallet && !userInfo) {
    return null;
  }

  const { data: referredUser, isLoading } =
    affiliateWallet && isAddress(affiliateWallet)
      ? useUserByWalletAddressQuery(affiliateWallet)
      : { data: null, isLoading: false };

  const user = referredUser || userInfo?.data || null;

  return (
    <>
      {hasDivider && <Divider sx={{ my: 6 }} />}
      <Box
        display="flex"
        sx={{ display: 'flex', gap: '88px', width: '100%' }}
        alignItems="center"
      >
        <Typography fontSize="16px" fontWeight="300" color="text.secondary">
          Referred by
        </Typography>

        {isLoading && <Skeleton variant="rounded" height={14} width={80} />}

        {!isLoading && (
          <>
            {!user?.wallet_address && (
              <Typography fontSize="14px" fontWeight="400" color="error">
                Invalid Referrer
              </Typography>
            )}

            {!!user?.wallet_address && (
              <Box display="flex" alignItems="center" gap={2}>
                <UserAvatar
                  name={user.username}
                  src={user.avatar}
                  walletAddress={user.wallet_address}
                  size={32}
                />

                <Box
                  display="flex"
                  flexDirection="column"
                  gap={0.5}
                  justifyContent="center"
                >
                  <Typography
                    fontSize={14}
                    fontWeight={400}
                    color="text.primary"
                  >
                    {user.username}
                  </Typography>

                  <Typography
                    fontSize={12}
                    fontWeight={300}
                    color="text.secondary"
                  >
                    {truncAddress(user.wallet_address)}
                  </Typography>
                </Box>
              </Box>
            )}
          </>
        )}
      </Box>
    </>
  );
}
