import { createTheme } from '@mui/material';

import { UncheckedIcon } from '../components/icons';

import '@fontsource/kanit';
import '@fontsource/montserrat';

const primaryDark = {
  main: '#38FF93',
};

const primaryBackground = 'linear-gradient(180deg, #97F320 0%, #20F368 100%)';
const theme = createTheme({
  palette: {
    mode: 'dark',
    primary: primaryDark,
    secondary: {
      main: '#fff',
    },
    background: {
      default: '#090C11',
      active: '#182920',
    },
    text: {
      primary: '#fff',
      secondary: '#ACAFB3',
      active: primaryDark.main,
    },
    error: {
      main: '#B91C1C',
    },
    success: {
      main: '#38FF93',
    },
  },
  spacing: 4,
  typography: {
    h1: {
      fontSize: '3rem',
    },
    h2: {
      fontSize: '2rem', // 32px
    },
    h3: {
      fontSize: '1.5rem', // 24px
    },
    h4: {
      fontSize: '1.25rem', // 20px
    },
    h5: {
      fontSize: '1.1875rem', // 19px
    },
    h6: undefined,
    subtitle1: {
      fontSize: '1rem',
      fontWeight: 700,
    },
    subtitle2: {
      fontSize: '0.875rem', // 14px
      fontWeight: 700,
    },
    body1: {
      fontSize: '1rem', // 16px
    },
    body2: {
      fontSize: '0.875rem', // 14px
    },
    caption: {
      fontSize: '0.75rem', // 12px
      fontWeight: 500,
    },
    fontFamily: 'Kanit',
  },
  components: {
    MuiLink: {
      defaultProps: {
        underline: 'none',
      },
      styleOverrides: {
        root: {
          color: primaryDark.main,
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderRadius: '12px',
        },
      },
    },
    MuiButton: {
      defaultProps: {
        variant: 'contained',
      },
      styleOverrides: {
        root: ({ ownerState, theme }) => ({
          width: 'fit-content',
          textTransform: 'none',
          borderRadius: 100,
          ...(ownerState.size === 'large' && {
            height: '40px',
            fontSize: '16px',
            lineHeight: '16px',
            px: '24px',
            py: '12px',
          }),
          ...(ownerState.size === 'small' && {
            height: '32px',
            fontSize: '14px',
            lineHeight: '14px',
            px: '16px',
            py: '9px',
          }),
          '&:disabled': {
            color: ownerState.variant === 'contained' ? '#9CA3AF' : '#6D7073',
          },
          ...(ownerState.color === 'primary' && {
            ...(ownerState.variant === 'contained' && {
              background: theme.palette.primary.main,
              fontWeight: 600,
              ':hover': {
                background: '#60FFA9',
              },
              ':active': {
                background: '#2DCF77',
              },
            }),
            ...(ownerState.variant === 'outlined' && {
              border: '1px solid #38FF93',
              background: 'none',
              color: 'white',
              ':hover': {
                background: '#182920',
              },
              ':active': {
                background: '#175232',
              },
            }),
          }),
          ...(ownerState.color === 'error' && {
            ...(ownerState.variant === 'contained' && {
              background: theme.palette.error.main,
              fontWeight: 600,
              ':hover': {
                background: '#E53935',
              },
              ':active': {
                background: '#7A1616',
              },
            }),
            ...(ownerState.variant === 'outlined' && {
              border: '1px solid #B91C1C',
              background: 'none',
              color: 'white',
              ':hover': {
                background: '#3D1111',
              },
              ':active': {
                background: '#7A1616',
              },
            }),
          }),
        }),
      },
    },

    MuiIconButton: {
      defaultProps: {
        color: 'primary',
      },
      styleOverrides: {
        root: ({ ownerState, theme }) => ({
          '&:hover': {
            backgroundColor: `${theme.palette.primary.main}14`,
          },
          ...(ownerState.color === 'primary' && {
            stroke: theme.palette.text.primary,
            fill: theme.palette.text.primary,
          }),
          ...(ownerState.color === 'secondary' && {
            stroke: theme.palette.text.secondary,
            fill: theme.palette.text.secondary,
          }),
        }),
      },
    },
    MuiCheckbox: {
      defaultProps: {
        icon: <UncheckedIcon />,
      },
    },
    MuiIcon: {
      styleOverrides: {
        root: ({ ownerState, theme }) => ({
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          ...(ownerState.color === 'primary' && {
            stroke: theme.palette.text.primary,
            fill: theme.palette.text.primary,
          }),
          ...(ownerState.color === 'secondary' && {
            stroke: theme.palette.text.secondary,
            fill: theme.palette.text.secondary,
          }),
        }),
      },
    },
    MuiCard: {
      defaultProps: {
        variant: 'outlined',
      },
      styleOverrides: {
        root: ({ ownerState, theme }) => ({
          display: 'flex',
          flexDirection: 'column',
          padding: theme.spacing(3),
          borderRadius: '8px',
          gap: theme.spacing(4),
          border:
            ownerState.variant === 'outlined' ? '1px solid #2D2F33' : 'none',
          backgroundColor: '#090C11',
          cursor: 'pointer',
          // '&:hover': {
          //   borderColor: 'white',
          // },
        }),
      },
    },
    MuiCardContent: {
      styleOverrides: {
        root: {
          padding: 0,
        },
      },
    },
    MuiCardActions: {
      styleOverrides: {
        root: {
          padding: 0,
        },
      },
    },
    MuiTable: {
      styleOverrides: {
        root: {
          borderCollapse: 'separate',
          borderSpacing: 0,
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: ({ ownerState, theme }) => ({
          border: 'none',
          padding:
            ownerState.padding !== 'none'
              ? `${theme.spacing(3)} ${theme.spacing(1)}`
              : 0,
        }),
      },
    },
    MuiAvatar: {
      styleOverrides: {
        root: {},
      },
    },
    MuiDivider: {
      variants: [
        {
          props: { variant: 'dashed', orientation: 'horizontal' },
          style: {
            backgroundImage:
              'linear-gradient(to right, #828282 50%, rgba(255,255,255,0) 0%)',
            backgroundPosition: 'bottom',
            backgroundSize: '10px 1px',
            backgroundRepeat: 'repeat-x',
          },
        },
      ],
      styleOverrides: {
        root: ({ ownerState }) => ({
          borderWidth: 0,
          ...(ownerState.orientation === 'horizontal' && {
            height: 1,
            width: '100%',
            background: '#2D2F33',
          }),
          ...(ownerState.orientation === 'vertical' && {
            width: 1,
            background: '#2D2F33',
          }),
        }),
      },
    },
    MuiDrawer: {
      styleOverrides: {
        modal: {
          backdropFilter: 'blur(8px)',
        },
        paper: {
          backgroundColor: '#090C11',
          backgroundImage: 'none',
          width: '600px',
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        root: ({ theme }) => ({
          border: '1px solid',
          borderColor: theme.palette.primary.main,
          '& .MuiSelect-select': {
            padding: '12px',
          },
        }),
      },
    },
    MuiTabs: {
      styleOverrides: {
        root: {
          color: '#9B9FA3',
        },
        indicator: {
          display: 'flex',
          justifyContent: 'center',
          backgroundColor: 'transparent',
        },
        flexContainer: {
          height: '48px',
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          color: 'inherit',
          minHeight: 'unset',
          minWidth: 'unset',
          px: 5,
          py: 4,
          borderRadius: '12px',
          textTransform: 'none',
          whiteSpace: 'nowrap',
          '&:not(.Mui-selected):hover': {
            background: '#182920',
            color: '#F0F5FA',
          },
          '&.Mui-selected': {
            color: 'white',
            borderBottom: '1px solid #38FF93',
            borderBottomLeftRadius: '0px',
            borderBottomRightRadius: '0px',
          },
        },
      },
    },

    MuiPaper: {
      styleOverrides: {
        root: {
          backgroundColor: '#2E3033',
        },
      },
    },
    MuiAlert: {
      styleOverrides: {
        root: ({ ownerState }) => ({
          ...(ownerState.severity === 'info' && {
            backgroundColor: '#60a5fa',
          }),
        }),
      },
    },
    MuiSkeleton: {
      styleOverrides: {
        rounded: {
          borderRadius: '20px',
        },
        root: {
          backgroundColor: '#222426',
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          backgroundColor: '#383B3D',
          borderRadius: '16px',
          color: '#F0F5FA',
          padding: '4px 12px',
          textAlign: 'center',
          fontSize: '14px',
        },
        arrow: {
          color: '#383B3D',
        },
      },
    },
    MuiSwitch: {
      styleOverrides: {
        root: {
          width: 32,
          height: 16,
          padding: 0,
          display: 'flex',

          '& .MuiSwitch-track': {
            borderRadius: 12,
            width: 32,
            height: 16,
            backgroundColor: '#6D7073',
            opacity: 1,
          },
          '& .MuiSwitch-switchBase': {
            padding: 2,

            '&.Mui-checked': {
              transform: 'translateX(12px)',
              color: '#FFFFFF',

              '& + .MuiSwitch-track': {
                opacity: 1,
              },
            },
          },
          '& .MuiSwitch-thumb': {
            boxShadow: 'none',
            width: 16,
            height: 12,
            borderRadius: 20,
            backgroundColor: '#F0F5FA',
            opacity: 1,
          },
        },
      },
    },
    MuiListItemText: {
      styleOverrides: {
        primary: {
          fontSize: '14px',
          fontWeight: 400,
          lineHeight: '14px',
        },
        root: {
          margin: 0,
        },
      },
    },
  },
});

theme.typography.body2 = {
  fontSize: '0.875rem',

  [theme.breakpoints.down('md')]: {
    fontSize: '0.75rem',
    lineHeight: '1.05rem',
  },
};

export default theme;

declare module '@mui/material/styles' {
  interface TypeText {
    active?: string;
  }

  interface SimpleTypeText {
    active?: string;
  }
}

declare module '@mui/material/styles' {
  interface TypeBackground {
    active?: string;
  }

  interface SimpleTypeBackground {
    active?: string;
  }
}

declare module '@mui/material/styles' {
  interface TypeIcon {
    dark?: string;
  }

  interface SimpleTypeIcon {
    dark?: string;
  }
}
declare module '@mui/material/Divider' {
  interface DividerPropsVariantOverrides {
    dashed: true;
  }
}
