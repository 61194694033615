import { Address } from 'viem';
import { useReadContract } from 'wagmi';

export type Slot0Output = [
  bigint, // sqrtPriceX96 (uint160)
  number, // tick (int24)
  number, // observationIndex (uint16)
  number, // observationCardinality (uint16)
  number, // observationCardinalityNext (uint16)
  number, // feeProtocol (uint8)
  boolean, // unlocked (bool)
];

const uniswapPoolAbi = [
  {
    type: 'function',
    name: 'slot0',
    stateMutability: 'view',
    outputs: [
      { name: 'sqrtPriceX96', type: 'uint160' },
      { name: 'tick', type: 'int24' },
      { name: 'observationIndex', type: 'uint16' },
      { name: 'observationCardinality', type: 'uint16' },
      { name: 'observationCardinalityNext', type: 'uint16' },
      { name: 'feeProtocol', type: 'uint8' },
      { name: 'unlocked', type: 'bool' },
    ],
    inputs: [],
  },
];

export function useGetUniswapSlot0(enabled: boolean = true) {
  return useReadContract({
    address: import.meta.env.VITE_UNISWAP_POOL_ADDRESS as Address,
    abi: uniswapPoolAbi,
    functionName: 'slot0',
    query: {
      select: (data: any) => data as Slot0Output,
      enabled: enabled,
    },
  });
}
