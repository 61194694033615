import { Box, Tooltip, TooltipProps } from '@mui/material';

import { FeedbackDetails } from '../../../web3/getErrorDetails';
import { FeedbackBanner } from '../FeedbackBanner';

export function ErrorWithTooltip({
  errorDetails,
  ...props
}: {
  errorDetails: FeedbackDetails;
} & Omit<TooltipProps, 'children' | 'title'>) {
  return (
    <Tooltip
      title={errorDetails.message ?? errorDetails.body}
      enterTouchDelay={0}
      {...props}
    >
      <Box>
        <FeedbackBanner
          type={errorDetails.type}
          title={errorDetails.title}
          body={errorDetails.body}
        />
      </Box>
    </Tooltip>
  );
}
