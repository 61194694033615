import { Box, Button, Typography } from '@mui/material';

import useGlobalModal from '../../../hooks/useGlobalModal';
import { DangerIcon, WarningImage } from '../../icons';
import { ModalContainer } from '../ModalContainer';

import { CreatorToUnstake } from './UnstakeModal';

export interface UnstakeWarningModalProps {
  creator: CreatorToUnstake;
}

export function UnstakeWarningModal({ creator }: UnstakeWarningModalProps) {
  const { showModal, hideModal } = useGlobalModal();

  const handleLearnMoreClick = async () => {
    showModal({ modalType: 'UnstakeTermsModal' });
  };

  const handleContinueClick = () => {
    hideModal('UnstakeWarningModal');
    showModal({ modalType: 'UnstakeModal', modalProps: { creator } });
  };

  const warningItems = [
    'If you unstake you will stop earning points!',
    'If your staked amount goes below your initial staked amount, you will loose your yield boost.',
    'Ensure you are not unstaking more than your initial staked amount to keep your yield boost.',
  ];

  return (
    <ModalContainer
      contentProps={{ sx: { width: 'min(calc(100% - 24px), 507px)' } }}
    >
      <Box display="flex" flexDirection="column" gap={6}>
        <Box display="flex" justifyContent="center">
          <WarningImage />
        </Box>
        <Typography fontSize={24} fontWeight={500}>
          IMPORTANT: Unstaking can lead to loss in your yield BOOST!
        </Typography>
        <Box display="flex" flexDirection="column" gap={3}>
          {warningItems.map((item, index) => (
            <Box
              key={index}
              display="flex"
              gap={2}
              alignItems="flex-start"
              sx={{
                svg: { height: '21px' },
              }}
            >
              <DangerIcon />

              <Typography fontSize="14px" fontWeight={300}>
                {item}
              </Typography>
            </Box>
          ))}
        </Box>

        <Box
          display="flex"
          gap={3}
          mt={4}
          flexDirection={{ xs: 'column', md: 'row' }}
        >
          <Button
            variant="contained"
            color="error"
            onClick={handleContinueClick}
            sx={{
              width: { xs: '100%', md: 'auto' },
            }}
          >
            Continue
          </Button>

          <Button
            variant="text"
            color="secondary"
            onClick={handleLearnMoreClick}
            sx={{
              width: { xs: '100%', md: 'auto' },
            }}
          >
            Learn more
          </Button>
        </Box>
      </Box>
    </ModalContainer>
  );
}
