import { createConfig, http } from 'wagmi';
import { base, baseSepolia, Chain, localhost } from 'wagmi/chains';
import { coinbaseWallet } from 'wagmi/connectors';

export const SUPPORTED_CONNECTORS_ID = ['coinbaseWalletSDK'];

import { Environment, getCurrentEnvironment } from './environment';
const myLocalhost = {
  ...localhost,
  id: 8453,
};

export const SUPPORTED_CHAINS: Record<Environment, [Chain, ...Chain[]]> = {
  [Environment.localhost]: [myLocalhost],
  [Environment.staging]: [baseSepolia],
  [Environment.production]: [base],
};

export const getSupportedChains = () => {
  const env = getCurrentEnvironment();
  return SUPPORTED_CHAINS[env];
};

const getCoinbaseWalletType = () => {
  const env = getCurrentEnvironment();
  if (env === Environment.localhost) {
    return 'eoaOnly';
  }
  return 'smartWalletOnly';
};

export const config = createConfig({
  chains: getSupportedChains(),
  ssr: true,
  connectors: [
    coinbaseWallet({
      appName: 'Altcoinist',
      preference: getCoinbaseWalletType(),
    }),
  ],
  transports: {
    [myLocalhost.id]: http(import.meta.env.VITE_RPC_URL),
    [baseSepolia.id]: http(),
  },
});
