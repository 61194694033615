import { Box, BoxProps } from '@mui/material';

export function ModalActionContainer({
  children,
  ...props
}: React.PropsWithChildren & BoxProps) {
  return (
    <Box
      {...props}
      sx={{
        display: 'flex',
        gap: 3,
        background: '#090C11',
        flexDirection: 'column',
        position: { xs: 'sticky', md: 'static' },
        bottom: 0,
        pt: { xs: 2, md: 0 },
        ...props.sx,
      }}
    >
      {children}
    </Box>
  );
}
