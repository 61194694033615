import { useState } from 'react';
import { useSnackbar } from 'notistack';
import { Address } from 'viem';
import { useCallsStatus, useWriteContracts } from 'wagmi/experimental';

import { Box, Button, Divider, Tooltip, Typography } from '@mui/material';

import { STAKED_ALTT_MAX_FRACTION_DIGITS } from '../../../constants/formatParams';
import { ZERO_ADDRESS } from '../../../constants/web3';
import { useAlttBalance } from '../../../hooks/useAlttBalance';
import { useAlttTokenInfo } from '../../../hooks/useAlttTokenInfo';
import { useAlttWethConverter } from '../../../hooks/useAlttWethConverter';
import useGlobalModal from '../../../hooks/useGlobalModal';
import { useMainTokenInfo } from '../../../hooks/useMainTokenInfo';
import { usePriceInUsd } from '../../../hooks/usePriceInUsd';
import { useWalletData } from '../../../hooks/useWalletData';
import { formatBigint } from '../../../lib/formatBigInt';
import formatBigintWithDecimals from '../../../lib/formatBigintWithDecimals';
import { formatApy } from '../../../lib/formats/formatApy';
import { ICreator } from '../../../types/ICreator';
import { StakeTokenType } from '../../../types/StakeToken';
import { FeedbackDetails } from '../../../web3/getErrorDetails';
import { useAllowanceReadMethod } from '../../../web3/hooks/ERC20/useAllowanceReadMethod';
import { getApproveContract } from '../../../web3/hooks/ERC20/useApproveSimulateContract';
import { getWrapEthContract } from '../../../web3/hooks/ERC20/useWrapEthSimulateContract';
import { useGetCreatorVaultByAddress } from '../../../web3/hooks/SubstakingFactory/useGetVaultByAddress';
import { getDepositWethContract } from '../../../web3/hooks/SubstakingVault/useDepositWethSimulateContract';
import { getStakeAlttContract } from '../../../web3/hooks/SubstakingVault/useStakeAlttSimulateContract';
import { RotatedArrowsUpDownIcon } from '../../icons/RotatedArrowsUpDownIcon';
import { TermsAndPolicyTypography } from '../../TermsAndPolicyTypography';
import { TokenAmountInput } from '../Inputs/TokenAmountInput';
import { ModalContainer } from '../ModalContainer';

import { ErrorWithTooltip } from './ErrorWithTooltip';

export type CreatorToStake = Pick<ICreator, 'wallet_address' | 'apy'>;

export interface StakeModalProps {
  creator: CreatorToStake;
}

const DEFAULT_STAKE_VALUE = 0n;

export function StakeModal({ creator }: StakeModalProps) {
  const [selectedToken, setSelectedToken] = useState(StakeTokenType.ETH);

  const { hideModal } = useGlobalModal();
  const { enqueueSnackbar } = useSnackbar();

  const { symbol, decimals } = useMainTokenInfo();
  const { symbol: alttSymbol, decimals: alttDecimals } = useAlttTokenInfo();

  const selectedTokenSymbol =
    selectedToken === StakeTokenType.ALTT ? alttSymbol : symbol;
  const selectedTokenDecimals =
    selectedToken === StakeTokenType.ALTT ? alttDecimals : decimals;

  const selectedTokenAddress =
    selectedToken === StakeTokenType.ALTT
      ? (import.meta.env.VITE_ALTT_ADDRESS as Address)
      : (import.meta.env.VITE_TOKEN_CONTRACT_ADDRESS as Address);

  const nextTokenToSelect =
    selectedToken === StakeTokenType.ALTT ? symbol : alttSymbol;

  const {
    address,
    formatBalance,
    balance,
    hasEnoughBalance: hasEnoughWethBalance,
  } = useWalletData();
  const {
    formatBalance: formatAlttBalance,
    balance: alttBalance,
    hasEnoughBalance: hasEnoughAlttBalance,
  } = useAlttBalance();

  const hasEnoughBalance =
    selectedToken === StakeTokenType.ALTT
      ? hasEnoughAlttBalance
      : hasEnoughWethBalance;

  const payingTokenBalance =
    selectedToken === StakeTokenType.ALTT ? alttBalance : balance;

  const [enteredAmountInTokenWei, setEnteredAmountInTokenWei] = useState<
    bigint | null
  >(DEFAULT_STAKE_VALUE);

  const enteredAmountInTokenFormatted = formatBigint(
    enteredAmountInTokenWei,
    selectedTokenDecimals,
  );

  const { convertAlttToEthWei, convertEthToAlttWei } = useAlttWethConverter();

  const payingAmountInEthWei =
    selectedToken === StakeTokenType.ALTT
      ? convertAlttToEthWei(enteredAmountInTokenWei)
      : enteredAmountInTokenWei;

  const payingAmountInEthFormatted = formatBigint(
    payingAmountInEthWei,
    decimals,
  );

  const stakingAmountInAlttWei =
    selectedToken === StakeTokenType.ALTT
      ? enteredAmountInTokenWei
      : convertEthToAlttWei(enteredAmountInTokenWei);

  const stakingAmountInAlttFormatted = formatBigintWithDecimals(
    stakingAmountInAlttWei,
    alttDecimals,
    selectedToken === StakeTokenType.ALTT
      ? undefined // show all digits if stake ALTT
      : STAKED_ALTT_MAX_FRACTION_DIGITS,
  );

  const { convertToUSD } = usePriceInUsd();

  const stakedAmountInUsdStr = payingAmountInEthFormatted
    ? convertToUSD(+payingAmountInEthFormatted)
    : 'N/A';

  const { data: creatorVaultAddress } = useGetCreatorVaultByAddress(
    creator?.wallet_address,
  );

  const { data: allowance } = useAllowanceReadMethod(
    address,
    creatorVaultAddress as Address,
    selectedTokenAddress,
  );

  const approved =
    enteredAmountInTokenWei === 0n ||
    (!!allowance &&
      !!enteredAmountInTokenWei &&
      allowance >= enteredAmountInTokenWei);

  const hasCorrectVaultAddress =
    creatorVaultAddress && creatorVaultAddress !== ZERO_ADDRESS;

  const hasEnoughBalanceToStake =
    enteredAmountInTokenWei === 0n ||
    (payingTokenBalance &&
      enteredAmountInTokenWei &&
      payingTokenBalance >= enteredAmountInTokenWei);

  const onStakeSuccess = (hash: string) => {
    enqueueSnackbar(`Staked successfully! TX hash: ${hash}`, {
      variant: 'success',
    });
    hideModal();
    window.location.reload();
  };

  const { data: batchTxId, writeContracts } = useWriteContracts();
  const { data: callsStatus, failureReason: batchStakeFailureReason } =
    useCallsStatus({
      id: batchTxId as string,
      query: {
        enabled: !!batchTxId,
        // Poll every second until the calls are confirmed
        refetchInterval: (data) =>
          data.state.data?.status === 'CONFIRMED' ? false : 1000,
      },
    });

  const batchStakeFailureMsg = (batchStakeFailureReason?.cause as any)
    ?.shortMessage as string | undefined;

  const disabledBatchMethod =
    !enteredAmountInTokenWei ||
    !creatorVaultAddress ||
    !address ||
    !hasEnoughBalanceToStake ||
    !hasCorrectVaultAddress;

  const handleStake = async () => {
    if (disabledBatchMethod) {
      return;
    }

    const contracts = [];
    if (!approved) {
      contracts.push(
        getApproveContract(
          creatorVaultAddress as Address,
          enteredAmountInTokenWei as bigint,
          selectedTokenAddress,
        ),
      );
    }

    if (selectedToken === StakeTokenType.ETH) {
      contracts.push(
        getWrapEthContract(enteredAmountInTokenWei),
        getDepositWethContract(
          creatorVaultAddress as Address,
          address,
          enteredAmountInTokenWei,
        ),
      );
    } else {
      contracts.push(
        getStakeAlttContract(
          creatorVaultAddress as Address,
          address,
          enteredAmountInTokenWei,
        ),
      );
    }

    writeContracts(
      {
        contracts,
      },
      {
        onError: (error) => {
          enqueueSnackbar(`Stake failed, because of error: ${error.message}`, {
            variant: 'error',
          });
        },
        onSuccess: onStakeSuccess,
      },
    );
  };

  const setMaxAvailableValue = () => {
    setEnteredAmountInTokenWei(payingTokenBalance ?? null);
  };

  const stakeErrorDetails = (() => {
    switch (true) {
      case !hasCorrectVaultAddress:
        return STAKE_ERRORS_MAP.INCORRECT_VAULT_ADDRESS;
      case !hasEnoughBalanceToStake:
        return STAKE_ERRORS_MAP.NOT_ENOUGH_BALANCE_TO_STAKE;
    }
    return null;
  })();

  const showErrorAfterEnteredBalance = !hasEnoughBalanceToStake;

  const tokenSelectionBtnDisabled =
    selectedToken === StakeTokenType.ETH && !alttBalance;

  const changeTokenError = tokenSelectionBtnDisabled
    ? `You don't have enough ALTT to stake`
    : '';

  const changeSelectedToken = () => {
    setSelectedToken((value) =>
      value === StakeTokenType.ALTT ? StakeTokenType.ETH : StakeTokenType.ALTT,
    );
  };

  return (
    <ModalContainer
      title="Stake"
      contentProps={{ sx: { width: 'min(calc(100% - 24px), 480px)' } }}
    >
      <Box mt={10}>
        <TokenAmountInput
          title="Enter amount"
          decimals={selectedTokenDecimals}
          value={enteredAmountInTokenWei}
          onValueChanged={setEnteredAmountInTokenWei}
          inputVariant="text"
          inputProps={{
            disabled: false,
          }}
        />

        <Box display="flex" justifyContent="space-between" mt={2}>
          <Typography
            color="text.secondary"
            fontSize={{ xs: 14, md: 16 }}
            fontWeight={300}
          >
            {selectedTokenSymbol}
          </Typography>

          <Tooltip title={changeTokenError} placement="top" arrow>
            <Box>
              <Button
                startIcon={
                  <RotatedArrowsUpDownIcon
                    rotate={+(selectedToken === StakeTokenType.ALTT)}
                    disabled={tokenSelectionBtnDisabled}
                  />
                }
                variant="text"
                disabled={tokenSelectionBtnDisabled}
                onClick={changeSelectedToken}
              >
                Enter {nextTokenToSelect}
              </Button>
            </Box>
          </Tooltip>
        </Box>

        <Box display="inline-block">
          <Typography
            component="span"
            sx={{
              fontSize: '14px',
              fontWeight: 300,
            }}
          >
            ≈ {stakedAmountInUsdStr}
          </Typography>

          <Typography
            component="span"
            sx={{
              fontSize: '14px',
              fontWeight: 300,
            }}
            color="text.secondary"
          >
            {' USD'}
          </Typography>
        </Box>

        {stakeErrorDetails && showErrorAfterEnteredBalance && (
          <ErrorWithTooltip errorDetails={stakeErrorDetails} sx={{ mt: 4 }} />
        )}

        <Divider sx={{ my: 6 }} />

        <Box display="flex" alignItems="center">
          <Box display="flex" flexDirection="column" gap={1}>
            <Typography
              color="text.secondary"
              fontSize={{ xs: 14, md: 16 }}
              fontWeight={300}
            >
              Available balance
            </Typography>

            {selectedToken === StakeTokenType.ETH && (
              <Typography
                color="text.primary"
                fontSize={{ xs: 14, md: 16 }}
                fontWeight={300}
              >
                {formatBalance()} {symbol}
              </Typography>
            )}

            {selectedToken === StakeTokenType.ALTT && (
              <Typography
                color="text.primary"
                fontSize={{ xs: 14, md: 16 }}
                fontWeight={300}
              >
                {formatAlttBalance()} {alttSymbol}
              </Typography>
            )}
          </Box>

          <Button
            variant="outlined"
            color="primary"
            sx={{
              ml: 'auto',
            }}
            disabled={!hasEnoughBalance}
            onClick={setMaxAvailableValue}
          >
            Max
          </Button>
        </Box>

        <Divider sx={{ my: 6 }} />

        <Box
          display="flex"
          gap={2}
          justifyContent="space-between"
          py={{ xs: 1, md: 2 }}
        >
          <Typography
            color="text.primary"
            fontSize={{ xs: 14, md: 16 }}
            fontWeight={300}
          >
            Pool APY
          </Typography>

          <Typography
            color="text.primary"
            fontSize={{ xs: 14, md: 16 }}
            fontWeight={300}
          >
            {formatApy(creator?.apy)}%
          </Typography>
        </Box>

        <Box
          display="flex"
          gap={2}
          justifyContent="space-between"
          py={{ xs: 1, md: 2 }}
        >
          <Typography
            color="text.primary"
            fontSize={{ xs: 14, md: 16 }}
            fontWeight={300}
          >
            Rewards frequency
          </Typography>

          <Typography
            color="text.primary"
            fontSize={{ xs: 14, md: 16 }}
            fontWeight={300}
          >
            Daily
          </Typography>
        </Box>

        <Box
          display="flex"
          gap={2}
          justifyContent="space-between"
          py={{ xs: 1, md: 2 }}
        >
          <Typography
            color="text.primary"
            fontSize={{ xs: 14, md: 16 }}
            fontWeight={300}
          >
            You&apos;re staking
          </Typography>

          <Typography
            color="text.primary"
            fontSize={{ xs: 14, md: 16 }}
            fontWeight={300}
          >
            {selectedToken === StakeTokenType.ETH && '≈'}{' '}
            {stakingAmountInAlttFormatted} {alttSymbol}
          </Typography>
        </Box>

        <Box
          display="flex"
          gap={2}
          justifyContent="space-between"
          py={{ xs: 1, md: 2 }}
        >
          <Typography
            color="text.primary"
            fontSize={{ xs: 14, md: 16 }}
            fontWeight={300}
          >
            You&apos;re paying
          </Typography>

          <Typography
            color="text.primary"
            fontSize={{ xs: 14, md: 16 }}
            fontWeight={300}
          >
            {enteredAmountInTokenFormatted} {selectedTokenSymbol}
          </Typography>
        </Box>

        {stakeErrorDetails && !showErrorAfterEnteredBalance && (
          <ErrorWithTooltip errorDetails={stakeErrorDetails} />
        )}

        <Tooltip title={batchStakeFailureMsg}>
          <Box
            sx={{
              py: 5,
            }}
          >
            <Button
              disabled={
                disabledBatchMethod ||
                (callsStatus && callsStatus.status !== 'CONFIRMED')
              }
              onClick={handleStake}
              fullWidth
              sx={{ width: '100%' }}
            >
              Stake
            </Button>
          </Box>
        </Tooltip>

        <TermsAndPolicyTypography textAlign="center" />
      </Box>
    </ModalContainer>
  );
}

const STAKE_ERRORS_MAP: Record<string, FeedbackDetails> = {
  PD: {
    title: 'User is not subscribed to creator.',
    body: 'Please, subscribe to this creator first.',
    type: 'warning',
  },
  IP: {
    title: 'Staking amount should be more than 0.',
    body: 'Please, enter larger than 0 amount to stake.',
    type: 'warning',
  },
  NOT_APPROVED: {
    title: 'Spending amount is not approved for this address.',
    body: 'Please, approve spending of selected amount of money.',
    type: 'warning',
  },
  INCORRECT_VAULT_ADDRESS: {
    title: "This creator doesn't have correct vault address",
    body: 'Creator should create vault by set up subscriptions prices',
    type: 'warning',
  },
  NOT_ENOUGH_BALANCE_TO_STAKE: {
    title: "You don't have enough balance to stake this amount",
    body: 'Top up your wallet or enter less amount to stake.',
    type: 'warning',
  },
};
