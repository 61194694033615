import { useAccount, useConnect, useDisconnect, useSwitchAccount } from 'wagmi';

import { Box, Button, Typography } from '@mui/material';

import useCurrentUserData from '../../../hooks/auth/useCurrentUserData';
import useGlobalModal from '../../../hooks/useGlobalModal';
import { truncAddress } from '../../../lib/truncHash';
import { WalletWarningImage } from '../../icons';
import { ModalContainer } from '../ModalContainer';

import { CreatorToStake } from './StakeModal';
import { LEARN_HOW_TO_FIX_WRONG_WALLET } from '../../../constants/externalLinks';

export interface IncorrectWalletWarningModalProps {
  creator: CreatorToStake;
}

export function IncorrectWalletWarningModal() {
  return (
    <ModalContainer
      contentProps={{ sx: { width: 'min(calc(100% - 24px), 480px)' } }}
    >
      <IncorrectWalletWarningContent />
    </ModalContainer>
  );
}

export function IncorrectWalletWarningContent() {
  const { hideModal } = useGlobalModal();

  const { data: userData } = useCurrentUserData();

  const { address } = useAccount();
  const { disconnect } = useDisconnect();

  const handleConnectWallet = async () => {
    disconnect();
    hideModal('IncorrectWalletWarningModal');
  };

  return (
    <Box display="flex" flexDirection="column" gap={6}>
      <Box display="flex" justifyContent="center">
        <WalletWarningImage />
      </Box>

      <Box>
        <Typography fontSize={24} fontWeight={500}>
          It looks like you`re processing with the wrong wallet!
        </Typography>

        <Typography fontSize={14} fontWeight={300} color="text.secondary">
          Reconnect your initial wallet to continue solve the issue.
        </Typography>
      </Box>

      <Box>
        <Typography fontSize={16} fontWeight={300} color="text.secondary">
          Initial wallet
        </Typography>

        <Typography fontSize={20} fontWeight={400} color="text.primary">
          {userData?.wallet_address
            ? truncAddress(userData?.wallet_address)
            : 'N/A'}
        </Typography>
      </Box>

      <Box>
        <Typography fontSize={16} fontWeight={300} color="text.secondary">
          Connected wallet
        </Typography>

        <Typography fontSize={20} fontWeight={400} color="text.primary">
          {truncAddress(address)}
        </Typography>
      </Box>
      <Box sx={{ display: 'flex', gap: 5 }}>
        <Box display="flex" gap={3} mt={4}>
          <Button
            variant="contained"
            color="primary"
            href={LEARN_HOW_TO_FIX_WRONG_WALLET}
            target="_blank"
            rel="noopener noreferrer"
          >
            Learn how to fix
          </Button>
        </Box>

        <Box display="flex" gap={3} mt={4}>
          <Button
            variant="outlined"
            color="primary"
            onClick={handleConnectWallet}
          >
            Disconnect wallet
          </Button>
        </Box>
      </Box>
    </Box>
  );
}
