import { IconButton, ListItemIcon, Typography, useTheme } from '@mui/material';

import { HOME_PATH } from '../constants/routings';
import { usePageContext } from '../renderer/usePageContext';

export default function MobileNavigationMenuItem({
  href,
  Icon,
  title,
}: {
  href: string;
  Icon: any;
  title: string;
}) {
  const pageContext = usePageContext();
  const isActive =
    pageContext.urlPathname === href ||
    pageContext.urlPathname.startsWith(href + HOME_PATH);

  const theme = useTheme();

  const textColor = !isActive
    ? theme.palette.text.primary
    : theme.palette.text.active;

  return (
    <IconButton
      href={href}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: 2,
        p: 4,
      }}
    >
      <ListItemIcon
        sx={{
          stroke: textColor,
          display: 'flex',
          justifyContent: 'center',
          svg: {
            width: '24px',
            height: '24px',
          },
        }}
      >
        {Icon}
      </ListItemIcon>
      <Typography fontSize="11px" color={textColor}>
        {title}
      </Typography>
    </IconButton>
  );
}
